<template>
  <div>
    <action-buttons
      :id="formData.id"
      :finished="formData.finished"
    />
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text><customer-card /></b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-sub-title class="mb-1">
            No: {{ formData.service_no }}
          </b-card-sub-title>
          <b-card-text>
            <service-card
              :service-data="formData"
              :works-data="worksData"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Seyahat</b-card-title>
          <b-card-text>
            <travel-card :travel-data="formData" />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Personel İmzası">
          <div v-if="formData.usersign">
            <b-img
              class="ml-auto mr-auto"
              :src="signUrl + formData.usersign"
              fluid-grow
            />
            <div class="text-center mt-1 text-primary">
              {{ formData.user_signed }}
            </div>
          </div>
          <div v-else>
            <vue-esign
              v-if="userSignImage === ''"
              ref="userSign"
              class="ml-auto mr-auto"
              style="border:1px dotted #cccccc"
              :width="500"
              :height="160"
              :is-crop="isCrop"
              :line-width="lineWidth"
              :line-color="lineColor"
              :bg-color.sync="bgColor"
              :is-clear-bg-color="false"
              format="image/jpg"
            />
            <div
              v-else
              class="text-center"
            >
              <b-img
                class="ml-auto mr-auto"
                :src="userSignImage"
                fluid-grow
              />
            </div>
            <div class="text-center mt-1">
              <b-button
                variant="warning"
                @click="handleReset('user')"
              >
                Temizle
              </b-button>
              <b-button
                variant="success"
                class="ml-1"
                :disabled="userSignImage !== ''"
                @click="handleGenerate('user')"
              >
                Kaydet
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Müşteri İmzası">
          <div v-if="formData.customersign">
            <b-img
              class="ml-auto mr-auto"
              :src="signUrl + formData.customersign"
              fluid-grow
            />
            <div class="text-center mt-1 text-primary">
              {{ formData.customer_signed }}
            </div>
          </div>
          <div v-else>
            <vue-esign
              v-if="customerSignImage === ''"
              ref="customerSign"
              class="ml-auto mr-auto"
              style="border:1px dotted #cccccc"
              :width="500"
              :height="160"
              :is-crop="isCrop"
              :line-width="lineWidth"
              :line-color="lineColor"
              :bg-color.sync="bgColor"
              :is-clear-bg-color="false"
              format="image/jpg"
            />
            <div
              v-else
              class="text-center"
            >
              <b-img
                class="ml-auto mr-auto"
                :src="customerSignImage"
                fluid-grow
              />
            </div>
            <div class="text-center mt-1">
              <b-button
                variant="warning"
                @click="handleReset('customer')"
              >
                Temizle
              </b-button>
              <b-button
                variant="success"
                class="ml-1"
                :disabled="customerSignImage !== ''"
                @click="handleGenerate('customer')"
              >
                Kaydet
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardText, BButton, BImg,
} from 'bootstrap-vue'
import vueEsign from 'vue-esign'
import CustomerCard from '@/views/Services/Components/CustomerCard.vue'
import MachineCard from '@/views/Services/Components/MachineCard.vue'
import TravelCard from '@/views/Services/Components/TravelCard.vue'
import ServiceCard from '@/views/Services/Components/ServiceCard.vue'
import ActionButtons from '@/views/Services/Components/ActionButtons.vue'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BButton,
    BImg,
    CustomerCard,
    MachineCard,
    ServiceCard,
    TravelCard,
    ActionButtons,
    vueEsign,
  },
  data() {
    return {
      lineWidth: 2,
      lineColor: '#000000',
      bgColor: '#FFFFFF',
      resultImg: '',
      isCrop: false,
      userSignImage: '',
      customerSignImage: '',
      signUrl: `${this.$store.state.app.baseURL}/media/signs/`,
      formData: {
        id: null,
        service_no: null,
        related_person: null,
        related_phone: null,
        notes: null,
        finished: null,
        usersign: null,
        customersign: null,
        invoiced_status: null,
        work_type: null,
        job: null,
        city_out1: null,
        city_out2: null,
        district_out1: null,
        district_out2: null,
        sdatetime_out: null,
        edatetime_out: null,
        city_return1: null,
        city_return2: null,
        district_return1: null,
        district_return2: null,
        sdatetime_return: null,
        edatetime_return: null,
        id_cities_out1: null,
        id_cities_out2: null,
        id_districts_out1: null,
        id_districts_out2: null,
        id_cities_return1: null,
        id_cities_return2: null,
        id_districts_return1: null,
        id_districts_return2: null,
        id_service_statuses: null,
        id_work_types: null,
        id_jobs: null,
        id_invoiced_statuses: null,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
        user_signed: null,
        customer_signed: null,
      },
      worksData: [],
    }
  },
  computed: {
    service() {
      return this.$store.getters['services/getService']
    },
    serviceWorks() {
      return this.$store.getters['serviceWorks/getService_works']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    service(data) {
      if (data.id) {
        this.setFormData(data)
        this.getCustomer(data.id_customers)
        this.getCustomerAddress(data.id_customer_address)
        this.getMachine(data.id_machines)
      }
    },
    serviceWorks(data) {
      this.setWorksData(data)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getService()
    this.getServiceWorks()
  },
  methods: {
    getService() {
      this.$store.dispatch('services/serviceView', this.$route.params.id)
    },
    getServiceWorks() {
      this.$store.dispatch('serviceWorks/service_worksList', {
        where: {
          'service_works.id_services': this.$route.params.id,
        },
      })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    setFormData(data) {
      this.formData.id = Number(data.id)
      this.formData.service_no = data.service_no
      this.formData.related_person = data.related_person
      this.formData.related_phone = data.related_phone
      this.formData.notes = data.notes
      this.formData.finished = data.finished
      this.formData.usersign = data.usersign
      this.formData.customersign = data.customersign
      this.formData.invoiced_status = { title: data.invoiced_status }
      this.formData.work_type = { title: data.work_type }
      this.formData.job = { title: data.job }
      this.formData.city_out1 = { title: data.city_out1 }
      this.formData.city_out2 = { title: data.city_out2 }
      this.formData.district_out1 = { title: data.district_out1 }
      this.formData.district_out2 = { title: data.district_out2 }
      this.formData.sdatetime_out = this.moment(data.sdatetime_out).format('DD.MM.YYYY hh:mm')
      this.formData.edatetime_out = this.moment(data.edatetime_out).format('DD.MM.YYYY hh:mm')
      this.formData.city_return1 = { title: data.city_return1 }
      this.formData.city_return2 = { title: data.city_return2 }
      this.formData.district_return1 = { title: data.district_return1 }
      this.formData.district_return2 = { title: data.district_return2 }
      this.formData.sdatetime_return = this.moment(data.sdatetime_return).format('DD.MM.YYYY hh:mm')
      this.formData.edatetime_return = this.moment(data.edatetime_return).format('DD.MM.YYYY hh:mm')
      this.formData.user_signed = this.moment(data.user_signed).format('DD.MM.YYYY hh:mm')
      this.formData.customer_signed = this.moment(data.customer_signed).format('DD.MM.YYYY hh:mm')
    },
    setWorksData(data) {
      if (data.length > 0) {
        const { worksData } = this
        data.forEach(e => {
          worksData.push({
            sdate: this.moment(e.sdate).format('DD.MM.YYYY HH:mm'),
            edate: this.moment(e.edate).format('DD.MM.YYYY HH:mm'),
          })
        })
        this.worksData = worksData
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    handleReset(type) {
      if (type === 'user') {
        this.userSignImage = ''
        this.$refs.userSign.reset()
      } else {
        this.customerSignImage = ''
        this.$refs.customerSign.reset()
      }
    },
    handleGenerate(type) {
      if (type === 'user') {
        this.$refs.userSign.generate().then(res => {
          this.userSignImage = res
          this.saveUserSign()
        }).catch(err => {
          alert(err)
        })
      } else {
        this.$refs.customerSign.generate().then(res => {
          this.customerSignImage = res
          this.saveCustomerSign()
        }).catch(err => {
          alert(err)
        })
      }
    },
    saveUserSign() {
      this.$store.dispatch('services/serviceUserSign', {
        id: this.formData.id,
        signImage: this.userSignImage,
      })
    },
    saveCustomerSign() {
      this.$store.dispatch('services/serviceCustomerSign', {
        id: this.formData.id,
        signImage: this.customerSignImage,
      })
    },
  },
}
</script>

<style scoped></style>
