<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="text-center mb-2"
      >
        <b-button
          v-if="finished === 1"
          variant="info"
          class="mr-1"
          :href="downloadURL"
          target="_blank"
        >
          <FeatherIcon icon="PrinterIcon" />
          Yazdır
        </b-button>
        <b-button
          variant="warning"
          class="mr-1"
          :to="'/services/edit/' + id"
        >
          <FeatherIcon icon="EditIcon" />
          Güncelle
        </b-button>
        <b-button
          v-b-modal.email-modal
          variant="success"
        >
          <FeatherIcon icon="SendIcon" />
          E-Posta Gönder
        </b-button>
      </b-col>
    </b-row>
    <email-modal />
  </div>
</template>
<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import EmailModal from '@/views/Services/Components/EmailModal.vue'

export default {
  name: 'ActionButtons',
  components: {
    BRow,
    BCol,
    BButton,
    EmailModal,
  },
  props: {
    id: Number,
    finished: Number,
  },
  computed: {
    downloadURL() {
      const urlParams = [
        `&id=${this.id}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/pdf/services?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
}
</script>

      <style scoped></style>
